import React from 'react'
import { Container } from 'reactstrap'
import { graphql } from 'gatsby'
import Parser from 'html-react-parser'

interface IProps {
  data: {
    stringsJson: any
  }
}
const PrivacyPage: React.FC<IProps> = ({
  data: {
    stringsJson: { privacyPolicy },
  },
}) => (
  <Container>
    <section className="pt-8">
      <div className="text-center">
        <h1 className="text-uppercase">Your right to privacy</h1>
      </div>
    </section>
    <section className="py-8">
      <div className="container">
        <div className="row">
          <div className="text-justify">
            {Parser(privacyPolicy.htmlContent)}
          </div>
        </div>
      </div>
    </section>
  </Container>
)

export default PrivacyPage

export const query = graphql`
  query PrivacyPolicyQuery {
    stringsJson(lang: { eq: "en" }) {
      ...PrivacyPolicy
    }
  }
`
